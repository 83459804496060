import React from 'react'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'

import CareersTemplate from './template'

function OurJobs({ data, pageContext }) {
  const { prismicCareersLandingPage } = data

  if (!prismicCareersLandingPage) return null
  const cmsData = prismicCareersLandingPage.data
  cmsData.body = cmsData.body2

  return (
    <CareersTemplate
      title={
        cmsData.our_jobs_page_title ? cmsData.our_jobs_page_title.text : null
      }
      path={pageContext.name}
      data={cmsData}
    />
  )
}

export default withPreview(OurJobs)

export const pageQuery = graphql`
  query CareersJobsQuery($locale: String!) {
    prismicCareersLandingPage(lang: { eq: $locale }) {
      data {
        bottom_banner_link_text {
          text
        }
        bottom_banner_link_url {
          url
        }
        bottom_banner_text {
          text
        }
        mantel_description {
          text
        }
        mantel_image {
          url
        }
        mantel_link_text {
          text
        }
        mantel_link_url {
          url
        }
        mantel_title {
          text
        }
        navigation_items {
          navigation_item_link_text {
            text
          }
          navigation_item_link_url {
            text
          }
        }
        body2 {
          ... on PrismicCareersLandingPageBody2ScriptEmbed {
            primary {
              script_embed
            }
            slice_type
          }
          ... on PrismicCareersLandingPageBody2BlockQuoteImage {
            primary {
              author_image {
                url
              }
              author_name {
                text
              }
              author_position {
                text
              }
              quote {
                text
              }
              usebackground
            }
            slice_type
          }
          ... on PrismicCareersLandingPageBody2VideoWithTitle {
            primary {
              poster {
                url
              }
              usebackground
              video {
                url
              }
              video_title {
                text
              }
            }
            slice_type
          }
        }
        our_jobs_page_title {
          text
        }
      }
    }
  }
`
