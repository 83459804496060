import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import tw from 'tailwind.macro'

const Inner = styled.div`
  ${tw`
    my-0
    ml-3
    max-w-2xl
    lg:mx-auto
  `}
`

function PageTitle({ title, bg }) {
  return title ? (
    <div className={`${bg || null}`}>
      <Inner>
        <h2
          className={classnames(
            'text-35px',
            'leading-none',
            'font-extrabold',
            'text-left',
            'pb-0',
            'xl:pb-3',
            'pt-0',
            'md:pt-3'
          )}
        >
          {title}
        </h2>
      </Inner>
    </div>
  ) : null
}

export default PageTitle
