import React from 'react'
import styled from 'styled-components'
import PageTitle from './components/PageTitle'
import PageWrap from '../../../components/Layouts/Global/PageWrap'
import RenderComponent from './renderer'

const PageContainer = styled.div``
const Container = styled.div`
  ${(props) => props.bg && `background: url(${props.bg}) top right no-repeat;`}

  div[class^='PageWrap__ContentContainer-'] {
    background: none;
  }
`

function CareersTemplate({ data, children, title, path, titleBackground, bg }) {
  const excludedFromLoop = ['page_heading', 'sub_navigation']

  if (!data) return null
  return (
    <Container bg={bg}>
      <RenderComponent component={{ slice_type: 'page_heading' }} data={data} />
      <RenderComponent
        component={{ slice_type: 'sub_navigation' }}
        data={{ ...data, currentPath: path }}
      />
      <PageTitle title={title} bg={titleBackground} />
      <PageWrap seo={null} backgrounds={null} bg={false}>
        <PageContainer>
          {data && data.body
            ? data.body.map((component) =>
                excludedFromLoop.includes(component.slice_type) ? null : (
                  <RenderComponent component={component} data={data} />
                )
              )
            : null}
          {children}
        </PageContainer>
      </PageWrap>
      <RenderComponent
        component={{ slice_type: 'bottom_banner' }}
        data={data}
      />
    </Container>
  )
}
export default CareersTemplate
